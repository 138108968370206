import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import AmorphusGradient from "../../images/background_amorphus_gradient.svg";
import {
  OpaliteTextStandard,
  OpaliteTextSubTitle,
  OpaliteTextTitle,
} from "../shared/opalite_text";
import Footer from "../shared/footer";
interface AboutUsProps {}

const AboutUs: React.FC<AboutUsProps> = (props) => {
  return (
    <Box height="100%" backgroundColor={COLORS_PRIMARY.BACKGROUND}>
      <Box padding="2rem" paddingTop="6rem">
        <OpaliteTextTitle marginBottom="50px">About Us</OpaliteTextTitle>
        <OpaliteTextTitle fontSize="2rem">
          Built in California. Influencing the entire world.
        </OpaliteTextTitle>
        <OpaliteTextStandard>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          convallis quam at orci placerat, vel dapibus orci accumsan. In
          fermentum pharetra velit, id aliquet dolor feugiat et. Mauris euismod
          suscipit massa, a facilisis arcu viverra id. Ut at enim eget ligula
          elementum pretium. Ut mi ligula, condimentum a accumsan ut, rutrum nec
          velit. Mauris pretium elit vitae euismod viverra. Sed id massa non
          lacus gravida congue vel ac augue.
        </OpaliteTextStandard>
      </Box>

      <Image src={AmorphusGradient} />
      <Footer />
    </Box>
  );
};

export default AboutUs;
