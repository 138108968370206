import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import AboutUs from "../components/about_us/about_us";
import Header from "../components/header/header";

function AllNFTPage() {
  return (
    <ChakraProvider>
      <Header />
      <AboutUs />
    </ChakraProvider>
  );
}

export default AllNFTPage;
